// mutationTypes
import { UPDATE_RECORD_INFO } from "@/store/shared/mutationTypes/record/record-mutation-types";

// vuex
import { createNamespacedHelpers } from "vuex";
const {
  mapState,
  mapGetters,
  mapActions,
  mapMutations
} = createNamespacedHelpers("inbox");

// mixins
import { dataTableFileVersionsMixin } from "@/mixins/shared/fileVersions/dataTableFileVersionsMixin";

/**
 * dataTableProjectFileVersionsMixin encapsulate Inbox module's dataTable file versions
 */
export const dataTableInboxFileVersionsMixin = {
  mixins: [dataTableFileVersionsMixin],
  computed: {
    ...mapState({
      /**
       * Selected record
       * Overwrite dataTableDocumentsMixin.record()
       * @return {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[] }}
       */
      record: "record",

      /**
       * File Versions
       * Overwrite dataTableDocumentsMixin.documents()
       * @return {{id:number, name:string, categoryId:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, flags:number, hasImage:boolean, owner:string, pages:number, recordTypeId:number, recordType:string, version:number, versionDate:string}[]}
       */
      items: "versions",

      /**
       * Selected document
       * Overwrite dataTableDocumentsMixin.currentDocument()
       * @return {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, owner:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, version: number, versionDate: string, versionOwner: string, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: any}[], flyingFields: {id:number, sequence:number}[]}}
       */
      currentDocument: "version",

      /**
       * current selected Document Category
       * remarks: Overwrite Abstract computed dataTableDocumentsMixin.currentDocumentCategory
       * @return {{id:number, name:string, formId:number, flags:number, categoryType: number, categoryTypeName: string, fields: {id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags:number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[]}[]}[]}
       */
      currentDocumentCategory: "versionCategory",

      /**
       * selected File Version
       */
      selectedFileVersion: "version",

      /**
       * selected File Version Category
       * remarks: Overwrite computed dataTableDocumentsMixin.selectedFileVersionCategory
       * @return {{id:number, name:string, formId:number, flags:number, categoryType: number, categoryTypeName: string, fields: {id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags:number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[]}[]}[]}
       */
      selectedFileVersionCategory: "versionCategory"
    }),
    ...mapGetters({
      /**
       * Overwrite dataTableMixin.selectedModuleItemId()
       */
      selectedModuleItemId: "selectedModuleItemId",

      /**
       * Overwrite dataTableMixin.selectedModuleItemName()
       */
      selectedModuleItemName: "selectedModuleItemName"
    })
  },
  methods: {
    ...mapActions({
      /**
       * Overwrite base methods
       */
      setDocuments: "setFileVersions",
      setDocument: "setFileVersion",
      setFileVersion: "setFileVersion",
      downloadFile: "downloadImageFileVersion"
    }),
    ...mapMutations({
      updateRecordInfo: UPDATE_RECORD_INFO
    })
  }
};
